
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from "./pages/About";
import Register from "./pages/Register";
import Main from "./pages/Main";
import IconPage from "./pages/IconPage"
import Slider from './pages/Slider';
import SmartPhone from "./component/SmartPhone"
import Bookmarks from "./component/Bookmarks/Bookmarks"
import './App.css';

function App() {
  console.log("App");
  return (
    <BrowserRouter>            
      <Routes>          
        <Route path="/about" element={<About />} />
        <Route path="/main" element={<Main />} />
        <Route path="/register" element={<Register />} />
        <Route path="/iconpage" element={<IconPage />} />
        <Route path="/smartphone" element={<SmartPhone />} />
        <Route path="/Slider" element={<Slider />} />
        <Route path="/" element={<Bookmarks/>} />        
      </Routes>      
    </BrowserRouter>
  );
}

export default App;
