/* eslint-disable jsx-a11y/alt-text */
//import mypic from '../img/me.png';
// <img src={mypic} className='mypic' />
import './Mainheader.css'
import { useNavigate } from 'react-router-dom'

function Mainheader() {

    const navigate = useNavigate()

    function handleClick(event) {
        navigate('/iconpage')
    }

    return (
        <header className='MainHeader'>
            <nav className='navbar'>
                <div className='container'>
                    <div className='leftarea'>
                        <a href="https://www.naver/com" >
                            <img src={`${process.env.PUBLIC_URL}/me.png`} className='mypic' />
                        </a>
                    </div>
                    <div className='rightarea'>
                        <ul className='rightbuttons'>
                            <li>
                                <input type="text" placeholder="서비스를 검색해 보세요" name="searchSearvice" id="idsearch" required />
                            </li>
                            <li>
                                <button type='button' className='btndefault btnbgnone' id='btnregpro' onSubmit={handleClick}>
                                    전문가 등록
                                </button>
                            </li>
                            <li>
                                <button type='button' className='btndefault btnbgnone' id='btnlogin'>
                                    로그인
                                </button>                            
                            </li>            
                            <li>
                                <button type='button' className='btndefault btnbgyellow' id='btnfreereg'>
                                    무료 회원 가입
                                </button>
                            </li>            
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Mainheader;