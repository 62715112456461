import './Bookmark.css';
const Bookmark = ({bm, deleteBookmark}) => {
    return (

        <li className="bm-item"
        key={bm.id}
        >
        <a href={bm.bmLink}>
            <img 
            src="https://www.google.com" 
            alt="" 
            className="image" />
            <span className="bm-title">
                Bookmark title
                </span>
        </a>
        <button
        className="btn-delete"                                
        onClick={ () => deleteBookmark(bm.id)}
        >
            Delete Bookmark
        </button>
        </li>   
    )}


    export default Bookmark;
