import React, {useState} from "react";
import {v4 as uuidv4} from 'uuid';
import './BookmarkForm.css';

const BookmarkForm =(props) => {
    const [bmTitle, setBmTitle] = useState('');
    const [bmLink, setBmLink] = useState('');

    const handlerSubmit = (e) => {
        e.preventDefault();
props.addBookmark({id: uuidv4(), bmTitle: bmTitle, bmLink: bmLink})
    }
    return (
<section className="bookmark-form">
<div className="card">
    <form 
        onSubmit={handlerSubmit}
    >
        <div className="form-control">
            <label htmlFor="">
                Title
            </label>
            <input 
            type="text"
            id="bmTitle"                             
            onChange={e => setBmTitle(e.target.value)}
             /></div>
        <div className="form-control">
            <label htmlFor="">
                URL
            </label>
            <input 
            id="bmLink" 
            type="text"
            onChange={e => setBmLink(e.target.value)}
             /></div>
        <button className="btn-add"
        type="submit"
        >Add Bookmark</button>  
    </form>
</div>
</section>
    );
}

export default BookmarkForm;