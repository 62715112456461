import React from "react";
import Calculator from "../apps/Calculator";
import Shopping from "../apps/Shopping";
import './SmartPhone.css';

function SmartPhone() {
  return (
    <div className="smartphone">
      <div className="apps-grid">
        <Calculator />
        <Shopping />
        <Calculator />
        <Shopping />
        <Calculator />
        <Shopping />
        <Calculator />
        <Shopping />                        
        {/* add more app components here */}
      </div>
    </div>
  );
}

export default  SmartPhone;