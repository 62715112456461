import './Menucontainer.css';
import CategoryA from './CategoryA';
import CategoryB from './CategoryB';
import CategoryC from './CategoryC';
import { useState } from 'react';


const menulist = {
    0: <CategoryA />,
    1: <CategoryB />,
    2: <CategoryC />,
}

function Menucontainer() {
    const [selectedmenu, setselectedmenu] = useState(0);

    const changeMenu = (menuIndex) => {
        setselectedmenu(menuIndex);
    }

    return (
        // Tab Menu       
        <div>
            <div className='menuarea'>
                <ul className='tabheader'>
                    <li className={`${selectedmenu === 0 ? 'activemenu':'menuitem'}`} onClick={() => changeMenu(0)}>MenuA</li>
                    <li className={`${selectedmenu === 1 ? 'activemenu':'menuitem'}`} onClick={() => changeMenu(1)}>MenuB</li>
                    <li className={`${selectedmenu === 2 ? 'activemenu':'menuitem'}`} onClick={() => changeMenu(2)}>MenuC</li>
                </ul>
            </div>
            <div className='contentarea'>
                {menulist[selectedmenu]}
            </div>            
        </div>
    );
}

export default Menucontainer;