import './IconContainer.css';

function IconContainer() {

    return (
        <div>
            Hello!
        </div>
    );
}

export default IconContainer;