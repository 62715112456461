import './Servicelist.css';
import Service from './Service';
import { useState, useEffect } from 'react';

function Servicelist() {
    const [services, setServices] = useState(null);

    useEffect(() => {
        setServices([
            {
                name:"Hello"
            },
            {
                name:"Who"
            },
            {
                name:"Yaho"
            },                        
            {
                name:"Hello"
            },
            {
                name:"Who"
            },
            {
                name:"Yaho"
            },                        
            {
                name:"Hello"
            },
            {
                name:"Who"
            },
            {
                name:"Yaho"
            },                        
    ])
    }, []);

    return (
        // Tab Menu       
        <div>
            <ul className='servicelist'>
            {
                services && services.map((item, index) => {
                    return <Service data={item} index={index} />
                })
            }
            </ul>
        </div>
    );
}

export default Servicelist;