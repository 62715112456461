import React from "react";
import './Shopping.css';

function Shopping() {
    return (
        <div className="shopping">
            <div className="shopping-icon">SP</div>
        </div>
    )
}

export default Shopping;