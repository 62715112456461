// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import './Slider.css';
import SmartPhone from '../component/SmartPhone';

export default function Slider() {
  return (
    <div id='sliderPG'>
        <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
            clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
        >
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
            <SwiperSlide><SmartPhone/></SwiperSlide>
        </Swiper>
    </div>
  );
}