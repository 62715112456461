import Mainheader from "../component/Mainheader";
import Mainbody from '../component/Mainbody';

function Main() {
    return ( 
        <div className="AppHome">                       
            <Mainheader/>
            <Mainbody/>
        </div>
    );
}

export default Main;