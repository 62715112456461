import './Mainbody.css';

import Menucontainer from './Menucontainer';
import Servicelist from './Servicelist';

function Mainbody() {

    return (
        // Tab Menu       
        <div>
            <Menucontainer />
            <Servicelist />
        </div>
    );
}

export default Mainbody;