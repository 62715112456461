import React, {useState} from "react";
import BookmarkForm from './BookmarkForm/BookmarkForm';
import BookmarkList from './BookmarkList/BookmarkList';
import './Bookmarks.css';


const Bookmarks = () => {
    const [bookmarks, setBookmarks] = useState([]);
    const [showForm, setShowform] = useState(true);
    const addBookmark =(bookmark)=> {        
        setBookmarks(prevBookmarks => [
            ...prevBookmarks,
            {
                id: bookmark.id,
                bmTitle: bookmark.bmTitle,
                bmLink: bookmark.bmLink,
            }
        ])
        console.log(bookmarks);
    }
    const deleteBookmark = (id) => {
setBookmarks(prevBookmarks => 
    prevBookmarks.filter(bm => bm.id !== id));
    console.log(id);
    }

    const openForm = () => {
        setShowform(true);
    }
    return (
        <div className="bookmarks">
            {
                showForm ?
                <div className="bookmark-container">
                    <div className="flex-container">
                    <BookmarkForm
                    addBookmark = {addBookmark}                    
                    />
                </div>                                                    
                    <BookmarkList
                        bookmarks={bookmarks}            
                        deleteBookmark = {deleteBookmark}
                        openForm = {openForm}
                    />                
                </div>
                :
                <BookmarkList
                bookmarks={bookmarks}            
                deleteBookmark = {deleteBookmark}
                openForm = {openForm}                
                />
            }
        </div>
    );
}

export default Bookmarks;