import IconContainer from "../component/IconContainer";

function IconPage() {
    return ( 
        <div >                       
            <IconContainer/>   
            Hi     
        </div>
    );
}

export default IconPage;