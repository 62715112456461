import './Service.css';

function Service(props) {
    console.log(props);
    const data = props.data;
    const index = props.index;

    return (
        // Tab Menu       
        <div class='service'>            
            Service={data.name}
            Service={index}
        </div>
    );
}

export default Service;